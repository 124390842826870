<template>
  <div class="container content">

      <div class="headline">
        <h1>{{ $t('message.home.status.header') }}</h1>
      </div>

      <div class="tile is-ancestor">
        <div class="tile is-parent is-12">
          <article class="tile is-child box">
            <p class="title">{{ $t('message.home.status.header') }}</p>
            <div class="columns" v-if="user && user.type == 'company'" v-html="$t('message.dashboard.txt-company')"></div>
            <div class="columns" v-if="!user || user.type != 'company'" v-html="$t('message.dashboard.txt')"></div>
          </article>
        </div>
      </div>  

      <div class="tile is-ancestor" v-if="activeUser.type == 'enduser'">
        <div class="tile is-parent is-4">
            <article class="tile is-child box">
                <p class="title">Omat koulutussetelit</p>
                <p class="summa">{{ activeUser.saldo.balance_total }}€</p>
                <p>
                    <b>Työsuhde-etu:</b>
                    {{ activeUser.saldo.balance_tyosuhde_etu }} €<br /><b
                        >Muutosturva:</b
                    >
                    {{ activeUser.saldo.balance_muutosturva }} €
                </p>
            </article>
        </div>
        <div class="tile is-parent is-4">
            <article class="tile is-child box">
                <p class="title">Käytetyt koulutussetelit</p>
                <p class="summa">{{ activeUser.saldo.used_total }}€</p>
                <p>
                    <b>Työsuhde-etu:</b>
                    {{ activeUser.saldo.used_tyosuhde_etu }} €<br /><b
                        >Muutosturva:</b
                    >
                    {{ activeUser.saldo.used_muutosturva }} €
                </p>
            </article>
        </div>
        <div class="tile is-parent is-4">
            <article class="tile is-child box">
                <p class="title">Varatut koulutussetelit</p>
                <p class="summa">{{ activeUser.saldo.reserved_total }}€</p>
                <p>
                    <b>Työsuhde-etu:</b>
                    {{ activeUser.saldo.reserved_tyosuhde_etu }} €<br /><b
                        >Muutosturva:</b
                    >
                    {{ activeUser.saldo.reserved_muutosturva }} €
                </p>
            </article>
        </div>
    </div>
    
    <div class="headline">
      <h1 v-if="activeUser && activeUser.type == 'enduser'">{{ $t('message.education.list.active-header') }}</h1>
      <h1 v-if="!activeUser || activeUser.type != 'enduser'">{{ $t('message.education.list.header') }}</h1>
    </div>
    <div v-for="educationBlock in educationsInBlocks" :key="educationBlock.id" class="tile is-ancestor koulutukset-boxes">
      <div v-for="education in educationBlock.els" :key="education.id" class="tile is-parent is-4">
       <article class="tile is-child box">

          <p class="title title-presentation" v-if="education.type == 'esittely'">
            {{ $t('message.education.list.item.presentation') }}
          </p>
          <p class="title" v-else-if="!enrollments.map(enrollment => enrollment.course_id).includes(education.id)">
            {{ $t('message.education.list.item.header') }}
          </p>
          <p class="title" v-else-if="enrollments.map(enrollment => enrollment.course_id).includes(education.id)">
            {{ $t('message.education.list.item.header-enrolled') }}
          </p>
          
          <img src="img/adinum_logo.png" v-if="education.img_url === null">
          <img :src="education.img_url" v-if="education.img_url !== null">
          <h2 class="koulutuksen-otsikko">{{education.name}}</h2>
          <p v-if="education.type == 'koulutus'" class="koulutuksen-ajankohta">{{formatDate(education.course_start)}} - {{formatDate(education.course_end)}}</p>
          <p class="koulutuksen-teksti">
            {{ education.description }}
          </p>
          <span v-if="user && user.type == 'education_partner'">
            <b-button
            :label="$t('message.education.list.publish')"
            type="is-primary"
            v-if="education.status == 'draft'"
            v-on:click="() => { changeEducationState(education, 'public') }" />

            <b-button
              :label="$t('message.education.list.unpublish')"
              type="is-primary"
              v-if="education.status == 'public'"
              v-on:click="() => { changeEducationState(education, 'draft') }" />
          </span>
          <br />

          <b-button v-if="user && enrollments.map(enrollment => enrollment.course_id).includes(education.id)"
            :label="education.type == 'koulutus' ? $t('message.education.list.read-more-and-enroll') : $t('message.education.list.read-more')"
            type="is-primary"
            @click="() => openEducation(education.id)" />

          <b-button v-if="user && !enrollments.map(enrollment => enrollment.course_id).includes(education.id)"
            :label="education.type == 'koulutus' ? $t('message.education.list.read-more-and-enroll') : $t('message.education.list.read-more')"
            type="is-primary"
            @click="() => openEducation(education.id)" />

          <b-button v-if="!user"
            style="visibility: hidden"
            label="&nbsp;"
            type="is-primary"
            @click="() => openEducation(education.id)" />
          
          <span v-if="education.type == 'koulutus'" class="koulutuksen-hinta">{{education.price}}<span>€</span></span>
       </article>
      </div>
    </div>  
  </div>
</template>


<script>
export default {
    data() {
        return {}
    },
    methods: {
      openEducation(id) {
        this.$router.push('/koulutus/' + id)
      },
      formatDate(_date) {
        return this.$moment(_date, 'YYYY-MM-DD hh:mm:ss').format('DD.MM.YYYY');
      },
    },

    computed: {
        educations() {
            return this.$store.getters['getCourses2']
        },
        educationsInBlocks() {
            var blocks = []
            var j = 0
            var block = {id: j, els: []}


            var edlist = null
            if (this.activeUser && this.activeUser.type == "enduser") {
                // if (!enrollments.map(enrollment => enrollment.course_id).includes(education.id)">
                var enrolled = this.enrollments.map(enrollment => enrollment.course_id)
                edlist = []
                this.educations.forEach(el => {
                  if (enrolled.includes(el.id)) {
                    edlist.push(el)
                  }
                })
            } else {
                edlist = this.educations
            }
            
            for (var i = 0; i < edlist.length; i++) {
              block.els.push(edlist[i])
              if (block.els.length == 3) {
                blocks.push(block)
                j++
                block = {id: j, els: []}
              }
            }
            if (block.els.length > 0) {
              blocks.push(block)
            }
            //console.log(blocks, edlist)
            return blocks
        },
        user() {
            return this.$store.getters['getUser']
        }, 
        enrollments() {
          return this.$store.getters['getEnrollments']
        },
    },
    mounted() {
       this.$store.dispatch("fetchEducations2");
       if (this.user) {
          this.$store.dispatch("fetchEnrollments");
       }
    }
}
</script>